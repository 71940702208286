import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './assets/tailwind.css'
import { Icon } from '@iconify/vue2';
import VueScrollmagic from 'vue-scrollmagic'

import LottieVuePlayer from "@lottiefiles/vue-lottie-player";
Vue.use(LottieVuePlayer);

Vue.use(VueScrollmagic, {
  vertical: true,
  globalSceneOptions: {
    
  },
  loglevel: 2,
  
})
// eslint-disable-next-line vue/multi-word-component-names
Vue.component('Icon',Icon)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
