import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  {
    path: '/',    
    component: () => import('../views/components/Layouts/Layout1'),
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('../views/HomeView2')
      }
    ]
  },
  {
    path: '/explore',    
    component: () => import('../views/components/Layouts/Layout1'),
    children: [
      {
        path: 'blog',
        name: 'indexBlog',
        component: () => import('../views/pages/Blog/Index')
      },
      {
        path: 'blog/:slug',
        name: 'DetailBlog',
        component: () => import('../views/pages/Blog/Detail')
      },
      {
        path: 'career',
        name: 'indexCareer',
        component: () => import('../views/pages/Career/Index')
      },
      {
        path: 'about-us',
        name: 'indexAbutUs',
      component: () => import('../views/pages/AboutUs/Index')
      },
    ]
  },
  {
    path: '/services',    
    component: () => import('../views/components/Layouts/Layout1'),
    children: [
      {
        path: 'web-application',
        name: 'webApp',
        component: () => import('../views/pages/Services/WebApp/Index.vue')
      },
      {
        path: 'mobile-application',
        name: 'mobileApp',
        component: () => import('../views/pages/Services/MobileApp/Index.vue')
      },
   
    ]
  },
  {
    path: '/showcase',    
    component: () => import('../views/components/Layouts/Layout1'),
    children: [
      {
        path: '/',
        name: 'indexShowcase',
        component: () => import('../views/pages/Showcase/Index.vue')
      },
      {
        path: ':slug',
        name: 'DetailShowcase',
        component: () => import('../views/pages/Showcase/Detail')
      },
    ]
  },
  {
    path: '/contact-us',    
    component: () => import('../views/components/Layouts/Layout1'),
    children: [
      {
        path: '/',
        name: 'indexContactUs',
        component: () => import('../views/pages/ContactUs/Index.vue')
      },
      
    ]
  },
  
  {
    path: '/recruitment',
    name: 'recruitment',
    component: () => import('../views/RecruitmentView.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
