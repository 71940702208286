<template>
  <div id="app">
    <!-- <nav class="_bg-theme-dark _h-[100px] _p-5 _z-[40] _relative">
      <div class="_container _flex _h-full _items-center _mx-auto">
        <div class="_h-full _text-white _flex _items-center">
          
          <span class="_tracking-[0.135em] _text-[32px] _align-middle _font-normal lg:_block">triwikrama</span>
        </div>
        <div class="sidebar _flex _gap-5 _ml-auto [&>a]:!_text-white _font-primary [&>a]:_font-normal" :class="toggleSidebar ? 'toggle-sidebar' : ''">
          <router-link to="/">Home</router-link>
          <router-link to="/about">About</router-link>
          <router-link to="/recruitment">Career</router-link>
          <router-link to="/services">Services</router-link>
          <router-link to="/portfolio">Portfolio</router-link>
          <router-link to="/product">Products</router-link>
          <router-link to="/contact-us">Contact Us</router-link>
        </div>
        <button class="_bg-transparent sm:_hidden _text-white _ml-auto" @click="toggleSidebar = !toggleSidebar">          
          <Icon icon="bx:menu" class="_text-4xl" />
        </button>
      </div>
    </nav> -->
    <router-view/>
    <!-- <footer class="_bg-black _min-h-[300px] _text-white _py-10">
      <div class="_container">
        <div class="_grid sm:_grid-cols-3 lg:_grid-cols-4 _gap-3">
          <div class="_order-last lg:_order-first sm:_col-span-3 lg:_col-span-2 _self-center">
            <div class="_h-full _text-white">
              <img src="./assets/logo.png" width="84" alt="" class=" _inline-block _mr-5">
              <span class="_tracking-[0.135em] _text-[32px] _align-middle _font-normal">triwikrama</span>
            </div>
          </div>
          <div class="_text-left">
            <h5 class="_font-bold _mb-5 _text-lg">
              Contact Us
            </h5>
            <div class="_mb-5">
              <ul class="_list-none [&>li]:_mb-2">
                <li>
                  <Icon icon="fluent:call-16-regular" class="_inline _mr-3" /> (022) 812xxxx
                </li>
                <li>
                  <Icon icon="fluent:mail-24-regular" class="_inline _mr-3" /> email@triwikrama.co.id
                </li>
        
              </ul>
            </div>
          </div>
         
          
          <div class="_text-left">
            <h5 class="_font-bold _mb-5 _text-lg">
              Our Office
            </h5>
            <div class="_mb-5">
              <div class="_font-bold _align-middle _mb-3">
                <Icon icon="heroicons-outline:location-marker" class="_inline _mr-3" />  Our Office
              </div>
              <div class="_text-sm _pl-[33px]">
                MTC Blok A25 No.590 Lt.3
                Kawasan Niaga, Jl. Soekarno-Hatta
                Kec. Buahbatu, Kota Bandung,
                Jawa Barat 40286
              </div>
            </div>
          
          </div>
        </div>
      </div>
      <hr class="_my-5"/>
      <div class="_container">
        <div class="_grid _grid-cols-1 md:_grid-cols-3 _justify-center lg:_justify-start _gap-3">
          <div class="_order-last lg:_order-first">
            Copyright 2022 Triwikrama
          </div>
          <div class="_flex _justify-center lg:_justify-center _gap-3">
            <a href="#">Privacy and Policy</a>
            <a href="#">Terms of Use</a>
          </div>
          <div class="_col-span-2 md:_col-span-1">
            <div class="_flex _justify-center lg:_justify-end _gap-5 ">
              <a href="#" class="_w-[30px] _h-[30px] _flex _items-center _justify-center _border _border-white _rounded-full _no-underline">
                <Icon icon="bxl:facebook-circle" class="_block" />
              </a>
              <a href="#" class="_w-[30px] _h-[30px] _flex _items-center _justify-center _border _border-white _rounded-full _no-underline">
                <Icon icon="akar-icons:twitter-fill" class="_block" />
              </a>
              <a href="#" class="_w-[30px] _h-[30px] _flex _items-center _justify-center _border _border-white _rounded-full _no-underline">
                <Icon icon="akar-icons:instagram-fill" class="_block" />
              </a>
              <a href="#" class="_w-[30px] _h-[30px] _flex _items-center _justify-center _border _border-white _rounded-full _no-underline">
                <Icon icon="akar-icons:youtube-fill" class="_block" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer> -->
  </div>
</template>
<script>
  export default {
    data: () => ({
        toggleSidebar: false,
    }),
    methods: {
      
    },
  }
</script>
<style lang="scss">
.vue-lottie-player {
  background: transparent !important;
}
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  
  color: #2c3e50;
}
.sidebar {
  @media (max-width: 639px) {
    position: fixed;
    width: 300px;
    height: 100%;
    top: 0;
    left: -300px;    
    padding: 30px 0;
    background: black;
    justify-content: flex-start;
    flex-direction: column;
    opacity: 0;
    visibility: hidden;
    transition: all .2s ease-in-out;
    z-index: 100;
    > a {
      width: 100%;
      display: block;
      text-align: left;
      padding: 10px 20px;
    }
  }

  &.toggle-sidebar {
    transform: translateX(300px);
    visibility: visible;
    opacity: 1;
    
  }
  nav {
    padding: 30px;
  
    a {
      font-weight: bold;
      color: #2c3e50;
  
      &.router-link-exact-active {
        color: #42b983;
      }
    }
  }
}
</style>
